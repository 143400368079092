import React from "react"

import { InfoBody, InfoContent } from "./faq"

export default function PrivacyPolicy() {
  return (
    <InfoBody title="Privacy Policy">
      <InfoContent>
        Privacy Policy - 03.18.20
        <br />
        A. Purpose
        <br />
        MountProxies (“Company,” “we,” and “us”) respects the privacy of its
        users (“you”) and has developed this Privacy Policy to demonstrate its
        commitment to protecting your privacy. This Privacy Policy describes the
        information we collect, how that information may be used, with whom it
        may be shared, and your choices about such uses and disclosures. We
        encourage you to read this Privacy Policy carefully when using our
        application or services or transacting business with us. By using our
        website or application (our “Service”), you are accepting the practices
        described in this Privacy Policy. If you have any questions about our
        privacy practices, please refer to the end of this Privacy Policy for
        information on how to contact us.
        <br />
        B. The manner in which MountProxies accesses, uses, stores, or shares
        Google user data:
        <br />
        We use your Google AuthO API to make logging into our website easier for
        our users. We store your email address in order to create your account
        with MountProxies.
        <br />
        C. Information we collect about you
        <br />
        The following is a non-exhaustive list of personal information we may
        collect and associated terms:
        <br />
        i. In General. We will collect information about you during your
        registration with and use of the Services. Some of this information can
        identify you (“personal information”) while other information will not.
        This information includes, but is not limited, to your name, date of
        birth, your various forms of contact information, location (city, state,
        and country), photos of you, your property, and residence, all other
        content you upload to the Services, browser and social media account
        login information, your social connections, and financial / payment
        processing information. By using the Service, you are authorizing us to
        gather, parse, share, sell, and retain information about you (personal
        information and otherwise) as well as data related to your use of the
        Services, subject to applicable law and regulation. When you provide
        personal information through our Service, the information may be sent to
        servers located in the United States and countries around the world.
        <br />
        ii. You may also be asked to allow Company to collect your location
        information from your device when you download or use the Service. In
        addition, we may collect and store any personal information you provide
        while using our Service or in some other manner. This may include
        identifying information, such as your name, address, email address and
        telephone number, and, if you transact business with us, ﬁnancial
        information. If you contact us with a customer service or other inquiry,
        you provide us with the content of that communication. If you choose to
        link, create, or log in to your Company account with a payment provider
        (e.g., Google Wallet) or social media service (e.g., Facebook), we may
        receive information about you or your connections from that site or app.
        <br />
        iii. Use of technologies to collect information. We use various
        technologies to collect information from your device and about your
        activities on our Service.
        <br />
        iv. Information collected automatically. We automatically collect
        information from your browser or device when you visit our Service. This
        information could include your IP address, device ID and type, your
        browser type and language, the operating system used by your device,
        access times, your mobile device’s geographic location while our
        application is actively running, and the referring website address.
        <br />
        v. Cookies and Use of Cookie Data. When you visit our Service, we may
        assign your device one or more cookies to facilitate access to our
        Service and to personalize your experience. Through the use of a cookie,
        we also may automatically collect information about your activity on our
        Service, such as the pages you visit, the time and date of your visits
        and the links you click. If we advertise, we (or third parties) may use
        certain data collected on our Service to show you Company advertisements
        on other sites or applications.
        <br />
        vi. Pixel tags. We may embed pixel tags (also called web beacons or
        clear GIFs) on web pages, ads, and emails. These tiny, invisible
        graphics are used to access cookies and track user activities (such as
        how many times a page is viewed). We use pixel tags to measure the
        popularity of our features and services. Ad companies also use pixel
        tags to measure the number of ads displayed and their performance (such
        as how many people clicked on an ad).
        <br />
        vii. Mobile Device IDs. If you’re using our app, we use mobile device
        IDs (the unique identiﬁer assigned to a device by the manufacturer), or
        Advertising IDs (for iOS 6 and later), instead of cookies, to recognize
        you. We do this to store your preferences and track your use of our app.
        Unlike cookies, device IDs cannot be deleted, but Advertising IDs can be
        reset in “Settings” on your iPhone. Ad companies also use device IDs or
        Advertising IDs to track your use of the app, track the number of ads
        displayed, measure ad performance and display ads that are more relevant
        to you. Analytics companies use device IDs to track information about
        app usage.
        <br />
        D. How we use the information we collect
        <br />
        We may use your personal information to:
        <br />
        i. Provide, deliver, maintain, and improve our products and services,
        and manage our business, including, for example, to facilitate payments
        and send receipts;
        <br />
        ii. Manage your account and provide you with customer support;
        <br />
        iii. Perform research and analysis about your use of, or interest in,
        our or others’ products, services, or content;
        <br />
        iv. Communicate with you by email, telephone and/or mobile devices about
        products or services that may be of interest to you either from us or
        other third parties;
        <br />
        v. Develop, display, and track content and advertising tailored to your
        interests on our Service and other sites, including providing our
        advertisements to you when you visit other sites;
        <br />
        vi. Website or mobile application analytics;
        <br />
        vii. Enforce or exercise any rights in our Terms of Use;
        <br />
        viii. Engage in any lawful commercial activity;
        <br />
        ix. Perform internal operations, including, for example, to prevent
        fraud and abuse of our Services; to troubleshoot software bugs and
        operational problems; to conduct data analysis, testing, and research;
        and to monitor and analyze usage and activity trends; and perform
        functions or services as otherwise described to you at the time of
        collection.
        <br />
        x. In all circumstances, we may perform these functions directly or use
        a third party vendor to perform these functions on our behalf who will
        be obligated to use your personal information only to perform services
        for us. Also, if you access our Service from a third party social
        platform, such as Facebook, we may share non-personal information with
        that platform to the extent permitted by your agreement with it and its
        privacy settings.
        <br />
        E. With whom we share your information
        <br />
        The following is a non-exhaustive list of third parties we may share
        your personal information with:
        <br />
        i. Information Shared with Other Users. When you register as a user of
        Company, your Company proﬁle will be viewable by other users and
        third-parties.
        <br />
        ii. Personal information. We do not share your personal information with
        others except as indicated in this Privacy Policy or when we inform you
        and give you an opportunity to opt out of having your personal
        information shared. We may share personal information with:
        <br />
        iii. Service providers: We may share information, including personal and
        ﬁnancial information, with third parties that perform certain services
        on our behalf. These services may include fulﬁlling orders, providing
        customer service and marketing assistance, performing business and sales
        analysis, ad tracking and analytics, member screenings, supporting our
        Service functionality, and collecting and processing payment(s) relative
        to your inquiries or transactions with third-party providers of Service.
        These service providers may have access to personal information needed
        to perform their functions but are not permitted to share or use such
        information for any other purposes.
        <br />
        iv. Other Situations. We may disclose your information, including
        personal information: a. In response to a subpoena or similar
        investigative demand, a court order, or a request for cooperation from a
        law enforcement or other government agency; to establish or exercise our
        legal rights; to defend against legal claims; or as otherwise required
        by law. In such cases, we may raise or waive any legal objection or
        right available to us. b. When we believe disclosure is appropriate in
        connection with efforts to investigate, prevent, or take other action
        regarding illegal activity, suspected fraud or other wrongdoing; to
        protect and defend the rights, property or safety of our company, our
        users, our employees, or others; to comply with applicable law or
        cooperate with law enforcement; or to enforce our Terms of Use or other
        agreements or policies. c. In connection with a substantial corporate
        transaction, such as the sale of our business, a divestiture, merger,
        consolidation, or asset sale, or in the unlikely event of bankruptcy. d.
        Aggregated and/or non-personal information. We may use and share
        non-personal information we collect under any of the above
        circumstances. We may also share it with third parties to develop and
        deliver targeted advertising on our Service and on websites or
        applications of third parties, and to analyze and report on advertising
        you see. We may combine non-personal information we collect with
        additional non-personal information collected from other sources. We
        also may share aggregated, non-personal information with third parties,
        including advisors, advertisers and investors, for the purpose of
        conducting general business analysis or other business purposes. For
        example, we may engage a data provider who may collect web log data from
        you (including IP address and information about your browser or
        operating system), or place or recognize a unique cookie on your browser
        to enable you to receive customized ads or content.
        <br />
        F. Third Party Websites
        <br />
        There may be a number of places on our Service where you may click on a
        link to access other websites that do not operate under this Privacy
        Policy. For example, if you click on an advertisement on our Service,
        you may be taken to a website that we do not control. These third- party
        websites may independently solicit and collect information, including
        personal information, from you and, in some instances, provide us with
        information about your activities on those websites. We recommend that
        you consult the privacy statements of all third-party websites you visit
        by clicking on the “privacy” link typically located at the bottom of the
        webpage you are visiting.
        <br />
        G. Data retention after account closure
        <br />
        If you close your Company account, we will retain certain information
        associated with your account for analytical purposes and recordkeeping
        integrity, as well as to prevent fraud, enforce our Terms of Use, take
        actions we deem necessary to protect the integrity of our Service or our
        users, or take other actions otherwise permitted by law. In addition, if
        certain information has already been provided to third parties as
        described in this Privacy Policy, retention of that information will be
        subject to those third parties’ policies.
        <br />
        H. Your choices about collection and use of your information
        <br />
        You can choose not to provide us with certain information, but that may
        result in you being unable to use certain features of our Service
        because such information may be required in order for you to register as
        a user; purchase products or services; ask a question; or initiate other
        transactions.
        <br />
        You can also control information collected by cookies. You can delete or
        decline cookies by changing your browser settings. Click “help” in the
        toolbar of most browsers for instructions.
        <br />
        I. How we protect your personal information
        <br />
        We take security measures to help safeguard your personal information
        from unauthorized access and disclosure. However, no system can be
        completely secure. Therefore, although we take steps to secure your
        information, we do not promise, and you should not expect, that your
        personal information, or other communications will always remain secure.
        Users should also take care with how they handle and disclose their
        personal information and should avoid sending personal information
        through insecure email.
        <br />
        J. Children’s privacy
        <br />
        Our Service is an adult only audience Service, the minimum age being at
        least 18 years of age, or other minimum age for use of Third-Party
        Services set by the laws of the jurisdiction where the Third-Party
        Services are to be received. We do not knowingly solicit, collect,
        maintain, or use personal information from persons under the age of 18.
        <br />
        K. No Rights of Third Parties
        <br />
        This Privacy Policy does not create rights enforceable by third parties
        or require disclosure of any personal information relating to users of
        the website.
        <br />
        L. Changes to this Privacy Policy
        <br />
        We will occasionally update this Privacy Policy. When we post changes to
        this Privacy Policy, we will revise the “last updated” date at the top
        of this Privacy Policy. We recommend that you check our Service from
        time to time to inform yourself of any changes in this Privacy Policy or
        any of our other policies.
        <br />
        M. How to contact us
        <br />
        If you have any questions about this Privacy Policy, please contact us
        by email or postal mail as follows: hello@mountproxies.com.
        <br />
        OTHER PROVISIONS
        <br />
        A. Choice of Law
        <br />
        These Terms are governed by and construed in accordance with the laws of
        the State of Illinois, U.S.A., without giving effect to any conflict of
        law principles.
        <br />
        B. Notice
        <br />
        Company may give notice by means of a general notice on the Services,
        electronic mail to your email address in your Account, or by written
        communication sent by first class mail or pre-paid post to your address
        in your Account. Such notice shall be deemed to have been given upon the
        expiration of 48 hours after mailing or posting (if sent by first class
        mail or pre-paid post) or 12 hours after sending (if sent by email). You
        may give notice to Company, with such notice deemed given when received
        by Company, at any time by first class mail or pre-paid post to:
        <br />
        MountProxies c/o Harvard Business Services, Inc. 16192 Coastal Highway
        Lewes Delaware, 19958
        <br />
        C. Assignment
        <br />
        You may not assign these Terms without Company’s prior written approval.
        Company may assign these Terms without your consent to: (i) a subsidiary
        or affiliate; (ii) an acquirer of Company’s equity, business or assets;
        or (iii) a successor by merger. Any purported assignment in violation of
        this section shall be void.
        <br />
        D. Relationship Between the Parties
        <br />
        No joint venture, partnership, employment, or agency relationship exists
        between you, Company or any Third Party Provider as a result of this
        Agreement or use of the Services.
        <br />
        E. Non-Waiver
        <br />
        Company’s failure to enforce any right or provision in these Terms shall
        not constitute a waiver of such right or provision unless acknowledged
        and agreed to by Company in writing.
        <br />
        F. Entire Agreement
        <br />
        These Terms, with the Privacy Policy and any specific guidelines or
        rules that are separately posted for particular services or offers in
        the Service, contain the entire agreement between you and the Company
        regarding use of the Service.
        <br />
        G. Amendment
        <br />
        These Terms are subject to change by the Company at any time.
        <br />
        H. Severability
        <br />
        If any provision of these Terms is held invalid, the remainder of these
        Terms shall continue in full force and effect.
      </InfoContent>
    </InfoBody>
  )
}
